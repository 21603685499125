<template>
  <v-container class="">
    <v-row justify="center">
      <v-col cols="12" class="d-flex justify-center">
        <span class="primary--text text-h3">Welcome</span></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
